import { AdminBidUserFreightType } from "../../api-interfaces/shipda-api/admin/adminBidUser";

const PROMOTION_FREIGHT_TYPE_OPTION_LIST: {
  label: string | number;
  value: AdminBidUserFreightType;
}[] = [
  { label: "FCL", value: "FCL" },
  { label: "오션티켓", value: "oceanTicket" },
  { label: "특송", value: "consolidation" },
  { label: "풀필먼트", value: "fulfillment" },
  { label: "수출", value: "export" },
];

export { PROMOTION_FREIGHT_TYPE_OPTION_LIST };
