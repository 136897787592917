import React, { useEffect } from "react";
import { useAtom } from "jotai";

import Loading from "../componentsToMoveToV1/Loading";
import { APP_NAME } from "../constants";
import { FORWARDING_ADMIN_AUTH_ATOMS } from "../jotaiStates/auth";

const LoggedInStateByAppName = {
  "shipda-admin": FORWARDING_ADMIN_AUTH_ATOMS.ADMIN_LOGGED_IN,
  "partner-admin": FORWARDING_ADMIN_AUTH_ATOMS.PARTNER_ADMIN_LOGGED_IN,
};

export default function withRequireAuth(
  WrappedComponent: React.ComponentType<unknown>
) {
  return () => {
    const [loggedIn, setLoggedIn] = useAtom(
      LoggedInStateByAppName[APP_NAME as "shipda-admin" | "partner-admin"]
    );

    // url로 direct로 들어왔을때를 위함
    useEffect(() => {
      // eslint-disable-next-line no-undef
      if (window.localStorage.getItem("accessToken")) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    }, [setLoggedIn]);

    useEffect(() => {
      if (typeof loggedIn === "boolean" && !loggedIn) {
        const backTo = window?.history?.state?.as;

        if (backTo) {
          location.href = `/login?backTo=${backTo}`;
        } else {
          location.href = `/login`;
        }
      }
    }, [loggedIn]);

    if (loggedIn) {
      return <WrappedComponent />;
    } else {
      return <Loading active={true} />;
    }
  };
}
