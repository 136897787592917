import { BankName } from "../../types/forwarding/adminSettlement";
import { PartnerBusinessArea } from "../../types/forwarding/partner";

const BANK_NAME_FILTER_OPTION_LIST: {
  label: string | number;
  value: BankName;
}[] = [
  { label: "SC제일은행", value: "SC" },
  { label: "기업은행", value: "기업" },
  { label: "우리은행", value: "우리" },
];

const SETTLEMENT_PURCHASE_COMPANY_TYPE_OPTION_LIST: {
  label: string | number;
  value: PartnerBusinessArea;
}[] = [
  { label: "해외파트너", value: "foreign" },
  { label: "국내파트너", value: "domestic" },
  { label: "관세사", value: "customs" },
  { label: "내륙운송사", value: "inland" },
  { label: "창고", value: "wareHouse" },
  { label: "세관", value: "customsDuty" },
  { label: "선사", value: "shipping" },
  { label: "기타", value: "etc" },
];

export {
  BANK_NAME_FILTER_OPTION_LIST,
  SETTLEMENT_PURCHASE_COMPANY_TYPE_OPTION_LIST,
};
